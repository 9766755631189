<template>
  <main-body-section class="geodatabase-page">
    <template #map>
      <div id="map" class="h-100 z-1"></div>
    </template>
    <base-panel title="انتخاب زمین" :rightPanel="false" class="text-center">
      <template #default>
        <vue-element-loading
          :active="farmsNameListLoadingState"
          text="دریافت اطلاعات ..."
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <farms-name-list
          :farmsNameList="farmsSource"
          :farmersNameList="farmsSource"
          :selectFarmLoading="selectFarmLoading"
          @selectFarm="selectFarm"
        ></farms-name-list>
      </template>
    </base-panel>
    <div class="geo-detail" v-if="selectedFarm">
      <h4 class="empty-title" v-if="formState === 'list'">
        برای ثبت یک نقطه را انتخاب کنید
      </h4>
      <button
        class="geo-detail-list-btn"
        @click="switchGeoForm('list', 0)"
        v-if="formState === 'new'"
      >
        لیست تأسیسات
      </button>

      <div class="farm-new-geo" v-if="formState === 'new'">
        <CRow>
          <CCol md="6">
            <CInput
              type="text"
              v-model="geoDataBaseDetail.title"
              placeholder="عنوان تأسیسات"
            />
          </CCol>
          <CCol md="6">
            <select v-model="geoDataBaseDetail.typeId">
              <option value="0" selected disabled>نوع تأسیسات</option>
              <option
                :value="item.value"
                v-for="(item, index) of geoDataBaseTypeSource"
                :key="index"
              >
                {{ item.label }}
              </option>
            </select>
          </CCol>
          <CCol md="12">
            <CTextarea
              type="text"
              v-model="geoDataBaseDetail.description"
              style="height: unset"
              placeholder="توضیحات تأسیسات"
              rows="5"
            ></CTextarea>
          </CCol>
          <CCol md="12">
            <input
              type="file"
              accept="image/*"
              ref="image"
              style="display: none"
              @change="onGeoDataBaseImageChange"
            />
            <CButton
              color="primary"
              size="lg"
              class="y-center-g-5 w-100"
              @click="$refs.image.click()"
            >
              <CIcon name="cil-camera" style="color: white" size="lg" />
              <span>{{
                imageFile?.name ?? "تصویر تأسیسات را انتخاب کنید"
              }}</span>
            </CButton>
          </CCol>
          <CCol md="12" class="mt-4 mb-3">
            <CButton
              @click="saveGeoDataBasePoint()"
              class="y-center-g-5 justify-content-center"
              color="success"
              block
            >
              <CIcon name="cil-check" />
              <span>ثبت</span>
            </CButton>
          </CCol>
        </CRow>
      </div>
      <div class="farm-geo-box" v-if="formState === 'list'">
        <no-data
          :show="farmGeoDataBaseList.length === 0"
          message="برای زمین تأسیسات ثبت نشده"
          class="emptyTitle"
        />
        <div v-if="farmGeoDataBaseList.length > 0">
          <div
            v-for="(item, index) of farmGeoDataBaseList"
            :key="index"
            :class="
              item.id == geoDataBaseDetail.id
                ? 'geo-list-info active'
                : 'geo-list-info'
            "
            @click="getDetail(item)"
          >
            <img :src="item.image" alt="" @click="showImage(item)" />
            <div class="geo-detail-text">
              <ul>
                <li>{{ item.title }} ({{ item.type }})</li>
                <li>{{ item.createDateTime }}</li>
              </ul>
              <p>{{ item.description }}</p>
            </div>
            <div class="geo-detail-btn">
              <button class="bg-info" @click="switchGeoForm('new', item.id)">
                ویرایش
              </button>
              <button class="bg-danger" @click="deleteGeoDataBase(item.id)">
                حذف
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <base-panel :rightPanel="true" v-if="selectedFarm" title="مشخصات زمین">
        <template #default>
          <farm-info
            :farmInfo="selectedFarm"
            :area="selectedFarmArea"
          ></farm-info>
        </template>
      </base-panel>
    </transition>

    <VueModal
      :title="geoDataBaseDetail.title"
      v-model="geoDataBaseImageModal"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      bg-class="animate__animated"
      :bg-in-class="`animate__fadeInDown`"
      :bg-out-class="`animate__fadeOutDown`"
    >
      <img :src="geoDataBaseDetail.image" class="geo-image" />
    </VueModal>
  </main-body-section>
</template>

<script>
import { mapActions } from "vuex";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { ZoomControl } from "mapbox-gl-controls";
import * as turf from "@turf/turf";
import { calculateBboxService } from "../../services/calculateBbox.service";
import polyGonStructure from "../../store/data/polygonFeature.json";
import { formatPolygonService } from "../../services/formatPolygon.service";
import * as moment from "jalali-moment";
import { apiUrlRoot } from "../../constants/config";
import PointStructure from "../../store/data/point.json";
import MainBodySection from "../../components/mainBodySection.vue";
import FarmsNameList from "../../components/map/FarmsNameList.vue";
import BasePanel from "../../components/map/BasePanel.vue";
import FarmInfo from "../../components/map/FarmInfo.vue";
import useVuelidate from "@vuelidate/core";
import NoData from "../../components/no-data.vue";

export default {
  name: "geoDataBase",
  components: {
    FarmInfo,
    BasePanel,
    FarmsNameList,
    MainBodySection,
    NoData,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      map: null,
      currentFarmId: 0,
      imageFile: null,
      currentLng: null,
      currentLat: null,
      farmsNameListLoadingState: false,
      loading: false,
      loadingText: "",
      geoDataBaseImageModal: false,
      selectFarmLoading: false,
      polygonCenter: null,
      formatedPolygon: null,
      selectedFarm: "",
      selectedFarmArea: null,
      farmsSource: [],

      formState: "list",
      geoDataBaseTypeSource: [],
      geoDataBaseDetail: {
        id: 0,
        typeId: 0,
        type: "",
        title: "",
        description: "",
        image: "",
        lng: null,
        lat: null,
      },
      farmGeoDataBaseList: [],
    };
  },
  methods: {
    ...mapActions("geoDataBase", [
      "GetAllByFarmId",
      "GetGeoType",
      "GetById",
      "Insert",
      "Edit",
      "Delete",
    ]),
    ...mapActions("farm", ["GetsTextValue", "Get"]),

    addPolygonLayer(polygon) {
      let geojason = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [polygon],
        },
      };
      if (this.map.getSource("maine")) {
        if (this.map.getLayer("maine")) {
          this.map.removeLayer("maine");
        }
        this.map.removeSource("maine");
      }
      if (this.map.getSource("indexImage")) {
        if (this.map.getLayer("indexImage-layer")) {
          this.map.removeLayer("indexImage-layer");
        }
        this.map.removeSource("indexImage");
      }
      this.map.addSource("maine", {
        type: "geojson",
        data: geojason,
      });
      this.map.addLayer({
        id: "maine",
        type: "fill",
        source: "maine",
        layout: {},
        paint: {
          "fill-color": "#0080ff",
          "fill-opacity": 0.5,
        },
      });
    },
    async loadFarmMap(cordinates) {
      polyGonStructure.features[0].geometry.coordinates = [cordinates];
      let farmArea = turf.area(polyGonStructure.features[0].geometry);
      this.selectedFarmArea = Intl.NumberFormat("fa").format(
        (farmArea / 10000).toFixed(2)
      );
      var polygon = turf.polygon([cordinates]);
      let center = turf.centerOfMass(polygon);
      this.polygonCenter = center["geometry"]["coordinates"];
      this.formatedPolygon = formatPolygonService.polygonreFormat(cordinates);
      let box = calculateBboxService.calculateBox(cordinates);
      this.addPolygonLayer(cordinates);
      this.map.fitBounds(box, { padding: 200 });
      this.map.on("load", () => {});
      await this.GetAllGeoWithFarmId();
    },
    async selectFarm(item) {
      this.selectFarmLoading = true;
      this.currentFarmId = item.value;
      var result = await this.Get({ id: item.value });
      this.kashtDatePersian = moment(result.cultivationsDate)
        .locale("fa")
        .format("YYYY-MM-D");
      result.kashtDatePersian = this.kashtDatePersian;
      this.selectedFarm = result;
      this.loadFarmMap(this.selectedFarm.cordinates);
      this.selectFarmLoading = false;
    },
    async GetAllFarms() {
      this.farmsNameListLoadingState = true;
      this.farmsSource = await this.GetsTextValue({
        farmerType: null,
        farmerId: null,
      });
      this.farmsNameListLoadingState = false;
    },
    setMarkerColor(marker) {
      this.map._markers.forEach((element) => {
        let makElement = element
          .getElement()
          .querySelectorAll('svg [fill="red"]');
        if (makElement.length > 0) {
          makElement[0].setAttribute("fill", "#3FB1CE");
        }
      });

      let markerElement = marker.getElement();
      markerElement
        .querySelectorAll('svg [fill="' + marker._color + '"]')[0]
        .setAttribute("fill", "red");
    },

    async GetAllGeoWithFarmId() {
      var result = await this.GetAllByFarmId({ farmId: this.currentFarmId });
      if (result) {
        this.farmGeoDataBaseList = [];
        result.forEach((element) => {
          let marker = new mapboxgl.Marker()
            .setPopup(new mapboxgl.Popup().setHTML(`<h5>${element.title}</h5>`))
            .setLngLat([element.lat, element.lng])
            .addTo(this.map);
          this.farmGeoDataBaseList.push({
            id: element.id,
            lng: element.lng,
            lat: element.lat,
            image: `${apiUrlRoot}/GeoDataBase/${element.image}`,
            type: element.type,
            typeId: element.typeId,
            title: element.title,
            description: element.description,
            createDateTime: element.createDateTime,
            marker: marker,
          });
        });
        if (this.farmGeoDataBaseList.length > 0)
          this.getDetail(this.farmGeoDataBaseList[0]);
      } else {
        this.$notify({
          title: "خطا رخ داده است",
          text: "برای زمین تأسیسات یافت نشد <br /><br />",
          type: "info",
        });
      }
    },
    async switchGeoForm(type, id) {
      this.formState = type;
      if (type === "new") await this.getModel(id);
    },
    onGeoDataBaseImageChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.imageFile = files[0];
    },
    async getModel(id) {
      if (id == 0) {
        this.imageFile = null;
        this.geoDataBaseDetail = {
          id: 0,
          typeId: 0,
          type: "",
          title: "",
          description: "",
          image: "",
          lng: null,
          lat: null,
        };
        this.GetGeoDataBaseTypeSource(0);
      } else {
        this.loading = true;
        let result = await this.GetById({ id: id });
        this.geoDataBaseDetail.id = id;
        this.geoDataBaseDetail.title = result.title;
        this.geoDataBaseDetail.description = result.description;
        this.geoDataBaseDetail.image = result.image;
        this.GetGeoDataBaseTypeSource(result.type);
        this.loading = false;
      }
    },
    getDetail(item) {
      this.geoDataBaseDetail.image = item.image;
      this.geoDataBaseDetail.title = item.title;
      this.geoDataBaseDetail.id = item.id;
      this.setMarkerColor(item.marker);
    },
    showImage(item) {
      this.geoDataBaseDetail.image = item.image;
      this.geoDataBaseDetail.title = item.title;
      this.setMarkerColor(item.marker);
      this.geoDataBaseImageModal = true;
    },
    async GetGeoDataBaseTypeSource(typeResult) {
      this.geoDataBaseTypeSource = [];
      var result = await this.GetGeoType();
      result.filter((item) => {
        let type = {
          label: item.title,
          value: item.id,
        };
        this.geoDataBaseTypeSource.push(type);
      });
      if (typeResult > 0) {
        this.geoDataBaseDetail.type = this.geoDataBaseTypeSource.filter(
          (item) => {
            return item.value == typeResult;
          }
        )[0];
      }
    },
    async deleteGeoDataBase(id) {
      this.$confirm({
        message: `نقطه مورد نظر حذف شود؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.deleteLoading = true;
            let result = await this.Delete({ id: id });
            this.deleteLoading = false;
            if (result) {
              if (result.succeeded) {
                this.$notify({
                  title: "پیام سیستم",
                  text: "سطر مورد نظر حذف شد <br /><br />",
                  type: "success",
                });
                await this.GetAllGeoWithFarmId();
              } else {
                this.$notify({
                  title: "خطا",
                  text: `${result.message} <br /><br />`,
                  type: "error",
                });
              }
            } else {
              this.$notify({
                title: "خطا",
                text: `خطا رخ داده است <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },
    async saveGeoDataBasePoint() {
      // Validate the form before saving
      if (this.geoDataBaseDetail.id == 0) {
        let formData = new FormData();
        formData.append("FarmId", this.currentFarmId);
        formData.append("Title", this.geoDataBaseDetail.title);
        formData.append("Type", this.geoDataBaseDetail.typeId);
        formData.append("Description", this.geoDataBaseDetail.description);
        PointStructure.geometry.coordinates = [
          this.currentLat,
          this.currentLng,
        ];
        let newPointString = JSON.stringify(PointStructure, null, 0);
        formData.append("coordination", newPointString);
        formData.append("Image", this.imageFile);

        this.loading = true;
        this.loadingText = "در حال ثبت اطلاعات ...";
        let result = await this.Insert({ geoDataBase: formData });
        if (result) {
          if (result.succeeded) {
            await this.GetAllGeoWithFarmId();
            await this.switchGeoForm("list", 0);
            this.$notify({
              title: "پیام سیستم",
              text: "نقطه مورد نظر ذخیره شد <br /><br />",
              type: "success",
            });
          } else {
            this.$notify({
              title: "خطا",
              text: `${result.message} <br /><br />`,
              type: "error",
            });
          }
        } else {
          this.$notify({
            title: "خطا",
            text: `خطا رخ داده است <br /><br />`,
            type: "error",
          });
        }
      } else {
        let formData = new FormData();
        formData.append("Id", this.geoDataBaseDetail.id);
        formData.append("Title", this.geoDataBaseDetail.title);
        formData.append("Type",this.geoDataBaseDetail.typeId);
        formData.append("Description", this.geoDataBaseDetail.description);
        formData.append("Image", this.imageFile);
        this.loading = true;
        this.loadingText = "در حال ویرایش اطلاعات ...";
        let result = await this.Edit({ geoDataBase: formData });

        if (result) {
          if (result.succeeded) {
            await this.GetAllGeoWithFarmId();
            await this.switchGeoForm("list", 0);
            this.$notify({
              title: "پیام سیستم",
              text: "نقطه مورد نظر ویرایش شد <br /><br />",
              type: "success",
            });
          } else {
            this.$notify({
              title: "خطا",
              text: `${result.message} <br /><br />`,
              type: "error",
            });
          }
        } else {
          this.$notify({
            title: "خطا",
            text: `خطا رخ داده است <br /><br />`,
            type: "error",
          });
        }
      }
      this.loading = false;
      this.modalShow = false;
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmlvY2hpYXBwIiwiYSI6ImNsMzhiOHdyaTA1OXYzZG13aGI5bXc0Y2YifQ.KK2ncJJG5hJWECxJ9jQwCA";
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/biochiapp/cl4chu50q000314qsi4ng5wj1",
      center: [53.12609431323281, 33.59332186046887], // starting position [lng, lat]
      zoom: 5,
      showZoom: true,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    this.map.addControl(new ZoomControl(), "bottom-left");
    this.map.on("load", () => {});
    this.map.on("draw.create", this.addPolygonLayer);
    this.map.on("click", (e) => {
      this.$confirm({
        message: `نقطه مورد نظر ثبت شود؟`,
        button: {
          no: "خیر",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            new mapboxgl.Marker().setLngLat(e.lngLat).addTo(this.map);
            this.currentLat = e.lngLat.lat;
            this.currentLng = e.lngLat.lng;
            await this.switchGeoForm("new", 0);
            this.$notify({
              title: "پیام سیستم",
              text: `اطلاعات تأسیسات را وارد کنید <br /><br />`,
              type: "info",
            });
          }
        },
      });
    });
    this.GetAllFarms();
    this.$confirm({
      message: `برای نمایش تأسیسات یک زمین را انتخاب کنید`,
      button: {
        yes: "باشه",
      },
    });
  },
};
</script>

<style>
/* Add your styles here */
.is-invalid {
  border-color: red;
}

.invalid-feedback {
  color: red;
}

.geodatabase-page .ads-wrapper img {
  width: 22rem !important;
}

.no-result-image {
  max-width: 10rem !important;
  width: 27vw !important;
}
</style>
<style scoped>
.geo-detail {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
  height: 35vh;
  overflow-y: auto;
  padding: 1rem;
  width: 25vw;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}

.geo-detail-new-btn {
  border: none;
  background-color: green;
  padding: 2px 2vw;
  color: #fff;
  border-radius: 9px;
  cursor: pointer;
}

.geo-detail-list-btn {
  border: none;
  background-color: #1976d2;
  padding: 2px 1vw;
  color: #fff;
  border-radius: 9px;
  cursor: pointer;
}

.geo-list-info {
  display: flex;
  width: 100%;
  border: 1px solid #bbb;
  border-radius: 14px;
  margin: 9px 0px;
  padding: 0px;
  cursor: pointer;
  flex-direction: row;
  justify-content: flex-start;
}

.geo-list-info.active {
  border: 3px solid greenyellow;
}

.geo-list-info img {
  width: 4vw;
  border-radius: 0px 14px 14px 0px;
  border-left: 1px solid #90a4ae;
}

.empty-title {
  text-align: center;
  width: 100%;
  color: #ff0000;
  padding: 4px 0px;
  font-weight: bold;
}

.farm-new-geo {
  display: flex;
  width: 100%;
  height: calc(100vh - 73vh);
  justify-content: center;
  overflow-y: auto;
}

.farm-new-geo select {
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  height: 75%;
  width: 100%;
}

.farm-geo-box {
  display: flex;
  width: 100%;
  height: calc(100vh - 73vh);
  width: 100%;
  padding: 0px;
  margin: 0px;
  align-items: stretch;
  flex-direction: column;
}

.farm-geo-box-text {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.farm-geo-box-text ul {
  display: flex;
  justify-content: space-between;
  padding: 0px 11px;
  list-style: none;
  border-bottom: 1px dashed #bbb;
  margin: 4px 11px;
}

.farm-geo-box-text ul li {
  font-size: 15px;
  color: #37474f;
  font-family: IRANSansWeb;
}

.farm-geo-box-text p {
  font-size: 12px;
  font-family: yekan;
  font-weight: bold;
  padding: 3px;
}

.geo-image {
  width: 100%;
  height: calc(100vh - 50vh);
}
.geo-detail-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.geo-detail-text ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  padding-right: 3px;
  flex-grow: 1;
}
.geo-detail-text ul li {
  border-bottom: 1px dashed #cfd8dc;
  margin: 0px 10px;
  font-size: 12px;
  font-weight: bold;
  color: #37474f;
}
.geo-detail-text p {
  font-size: 11px;
  padding: 4px;
  color: #607d8b;
  font-weight: 600;
}
.geo-detail-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}
.geo-detail-btn button {
  border: none;
  flex-grow: 1;
  width: 100%;
  margin: 2px 0px;
}
</style>
